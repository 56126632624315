/*
231224 TSN lazy loading
210118 TSN made util functions public, added sort by line, trim by line, replaceformatter

To DO:
add cookies to save names on players, and player num settings

Discovered this 1/16 or 1/17/21
topic type 4...compliment?
2020-09-25 06:45:50.814245
I love you, from Ya friend, source The Heart
 4801 |       4 | I love you                                                                                                                                                                                                                                                 |        |     0 |        0 |     1 |       0 |              | Ya friend    |        -1 | The Heart  | 
*/
import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Talk from "./pages/Talk";
//import { BrowserRouter } from 'react-router-dom';

function App() {
  //let topicNum = talkData.length
  // const [randNumb, setRandNumb] = useState(Math.floor(Math.random() * topicNum + 1));
  const [dispWidth, setDispWidth] = useState(window.innerWidth);

  ///// isMobile check
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Check if the initial window width indicates a mobile device
    setIsMobile(window.innerWidth < 768);
    setDispWidth(window.innerWidth);

    // Listen for window resize events
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setDispWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App" style={{ background: "lightgray" }}>
      <header className="App-header1">
        <nav
          className="navbar fixed-top navbar-expand-lg navbar-light gradientbg"
          style={{
            height: !isMobile ? "50px" : "40px",
            paddingTop: !isMobile ? "6px" : "1px",
          }}
        >
          <b>
            <div
              style={{
                marginLeft: !isMobile ? "8px" : "10px",
                fontSize: !isMobile ? "16px" : "13px",
                verticalAlign: "top",
              }}
            >
              DeltaPeng's Talking Point compilation (ver 2.240823
              {isMobile ? "_m" : ""})
            </div>
          </b>
        </nav>
      </header>

      <div className="Body" style={{ marginTop: !isMobile ? "50px" : "32px" }}>
        <Router>
          <Routes>
            <Route
              path="/"
              exact
              element={<Talk dispWidth={dispWidth} isMobile={isMobile} />}
            />
            <Route
              path="/allow/:code"
              exact
              element={<Talk dispWidth={dispWidth} isMobile={isMobile} />}
            />
          </Routes>
        </Router>
      </div>

      <div className="footer bg-gray-400 text-center pt-2">
        ©(2020/01/07 heroku)-2024 DeltaPeng
        <br />
        Like what you see? Powered by React + Bootstrap + ~Tailwind + Postgresql
        + Docker + Ubuntu
        <br />
      </div>
    </div>
  );
}

export default App;
